import React from 'react'

export const Moon = ({ darkMode }) => (
  <svg
    version='1.1'
    id='moon'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    x='0px'
    y='0px'
    heigth='17px'
    width='17px'
    viewBox='0 0 512 512'
    xmlSpace='preserve'
  >
    <g>
      <path
        fill={darkMode ? '#e08600' : '#000000'}
        d='M507.681,209.011c-1.297-6.991-7.323-12.112-14.433-12.262c-7.145-0.155-13.346,4.712-14.936,11.642
			c-15.26,66.498-73.643,112.941-141.978,112.941c-80.321,0-145.667-65.346-145.667-145.666
			c0-68.335,46.443-126.718,112.942-141.976c6.93-1.59,11.791-7.827,11.643-14.934c-0.149-7.108-5.269-13.136-12.259-14.434
			C287.545,1.454,271.735,0,256,0C187.62,0,123.333,26.629,74.98,74.981C26.629,123.333,0,187.62,0,256
			s26.629,132.667,74.98,181.019C123.333,485.371,187.62,512,256,512s132.667-26.629,181.02-74.981
			C485.371,388.667,512,324.38,512,256C512,240.278,510.547,224.469,507.681,209.011z M256,482C131.383,482,30,380.617,30,256
			c0-118.227,91.264-215.544,207.036-225.212c-14.041,9.63-26.724,21.303-37.513,34.681
			c-25.058,31.071-38.857,70.207-38.857,110.197c0,96.863,78.804,175.666,175.667,175.666c39.99,0,79.126-13.8,110.197-38.857
			c13.378-10.789,25.051-23.471,34.682-37.511C471.544,390.736,374.228,482,256,482z'
      />
    </g>
  </svg>
)
